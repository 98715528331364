import React from "react";
import { useState } from "react";
import styled from "styled-components";

const DropDown = ({ options, toggleDropdown, selectedOption, update }) => {
  const [isDrop, setIsDrop] = useState(false);
  return (
    <DropDownSection
      className="custom-dropdown"
      onClick={toggleDropdown}
      isDrop={isDrop}
    >
      <div className="options">
        <div
          className="option select"
          onClick={() => setIsDrop((prev) => !prev)}
        >
          {selectedOption}
          <img src="/assets/contact/Frame.jpg" alt="" className="vector_down" />
        </div>
        {options.map((option) => (
          <div
            key={option}
            className="option"
            onClick={() => {
              setIsDrop((prev) => !prev);
              update("type", option);
            }}
          >
            {option}
          </div>
        ))}
      </div>
    </DropDownSection>
  );
};

export default DropDown;

const DropDownSection = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  background-color: white;
  position: relative;
  .options {
    box-sizing: border-box;
    border: 1px solid #dbdbdb;
    background-color: white;
    width: 100%;
    display: grid;
    height: ${(props) => (props.isDrop ? "500px" : "50px")};
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    overflow: hidden;
    transition: 0.2s ease-in-out;
    .option {
      border-top: solid 1px #dbdbdb;
      cursor: pointer;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
      padding: 0 12px 0 16px;
      display: flex;
      align-items: center;
      .vector_down {
        width: 24px;
        height: 24px;
        transform: ${(props) =>
          props.isDrop ? "rotateZ(180deg)" : "rotateZ(0deg)"};
      }
    }
    .select {
      border-top: unset;
      justify-content: space-between;
    }
  }
`;
