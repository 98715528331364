import React from "react";
import "./css/index.css";
import { Link } from "react-router-dom";
const Finish = () => {
  return (
    <main className="finish-page">
      <img src="/assets/finish/logo.svg" alt="VIEW" />
      <div className="finish-title">상담 신청이 완료되었습니다</div>
      <div className="finish-sub">
        문의주신 내용은 담당자가 확인 후 <br />
        유선으로 답변드리도록 하겠습니다.
        <br />
        (최대 1영업일, 평균 2~3시간 이내)
      </div>
      <Link to={"/"}>메인으로</Link>
    </main>
  );
};

export default Finish;
