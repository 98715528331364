import React from "react";

const InputSection = ({ title, type, placeholder, update, value }) => {
  return (
    <div className="input_container">
      <label>{title}</label>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          update(type, e.target.value);
        }}
      />
    </div>
  );
};

export default InputSection;
