import React from "react";
import styled from "styled-components";

const TelFloating = styled.div`
  position: fixed;
  z-index: 1000;
  transform: translateZ(2px);
  right: 5%;
  bottom: 5%;
  width: 89px;
  height: 295px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 99px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  box-sizing: border-box;
  padding: 26px 11px;
  .line {
    width: 100%;
    height: 1px;
    background: #777;
  }
  & > a {
    transition: 0.2s ease-in;
    color: white;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-direction: column;
    align-items: center;
    & > img {
      width: 38px;
      margin-bottom: 5px;
    }
  }
  & > a:hover {
    opacity: 0.6;
  }
  @media screen and (max-width: 767px) {
    width: 68px;
    height: 226px;
    padding: 16px 6px;
    & > a {
      font-size: 12px;
      & > img {
        width: 26px;
      }
    }
  }
`;

function Floating() {
  return (
    <TelFloating>
      {layouts.map(({ img, title, link }, idx) => {
        return (
          <React.Fragment key={idx}>
            {idx > 0 ? <div className="line" /> : undefined}
            <a href={link}>
              <img src={`/assets/common/${img}.svg`} alt="" />
              <div>{title}</div>
            </a>
          </React.Fragment>
        );
      })}
    </TelFloating>
  );
}

export default Floating;

const layouts = [
  {
    img: "kakaoinquiry",
    title: "카카오문의",
    link: "https://pf.kakao.com/_aSCFxl",
  },
  {
    img: "quoote",
    title: "견적문의",
    link: "/contact",
  },
  {
    img: "tel",
    title: "전화문의",
    link: "tel:010-9345-9293",
  },
];
