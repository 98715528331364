/* eslint-disable no-undef */
import React, { useEffect, useReducer, useState } from "react";
import styled from "styled-components";
import Navigation from "../../components/Navgation/Navigation";
import DropDown from "../../components/DropDown/DropDown";
import { useCallback } from "react";
import InputSection from "../../components/Contact/InputSection";
import { telform } from "../../lib/lib";
import firebaseApp from "../../config/firebaseApp";
import { useNavigate } from "react-router-dom";

const options = [
  "아파트",
  "주택 별장",
  "상가",
  "공장",
  "사무실",
  "카페",
  "기타",
  "전체 리모델링",
  "부분리모델링",
];

export const InfoIntial = () => {
  return {
    name: "",
    tel: "",
    price: "",
    type: "아파트",
    feat: "",
    etc: "",
  };
};
function InfoReducer(state, { payload, type }) {
  switch (type) {
    case "dispatch/all":
      return payload;
    case "price":
      return { ...state, price: payload };
    case "name":
      return { ...state, name: payload };
    case "tel":
      return { ...state, tel: payload };
    case "type":
      return { ...state, type: payload };
    case "feat":
      return { ...state, feat: payload };
    case "etc":
      return { ...state, etc: payload };

    default:
      throw new Error(`Unhandled action type: ${type}`);
  }
}

const Fstore = firebaseApp.firestore();
function Contact({ agent }) {
  const navigate = useNavigate();
  const [isAgreement, setIsAgreement] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [state, dispatch] = useReducer(InfoReducer, undefined, InfoIntial);
  const onAgreementClick = () => {
    setIsAgreement((prev) => !prev);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const UpdateInfo = useCallback((type, value) => {
    let payload = value;
    if (type === "tel") {
      if (value.length < 13) {
        payload = telform(value);
        dispatch({
          type,
          payload,
        });
      }
      return;
    }
    dispatch({
      type,
      payload,
    });
  }, []);

  const SubmitContact = useCallback(async () => {
    if (isAgreement) {
      const obj = Object.assign({ timestamp: Date.now() }, state);
      await Fstore.collection("question").add(obj);
      navigate("/finish")
    }
  }, [state, isAgreement,navigate]);

  const layouts_arr = [
    {
      title: "고객명",
      placeholder: "고객명",
      type: "name",
      value: state.name,
    },
    {
      title: "연락처",
      placeholder: "연락처",
      type: "tel",
      value: state.tel,
    },
    {
      title: "예산",
      placeholder: "예산",
      type: "price",
      value: state.price,
    },
  ];

  useEffect(() => {
    const img = new Image();
    img.src = "/assets/video/contact-thumbnail.png";
    const vide = document.createElement("video");
    const mov = document.createElement("source");
    const webm = document.createElement("source");
    mov.type = "video/mp4";
    mov.src = "/assets/video/contact-3.mov";
    webm.type = "video/webm";
    webm.src = "/assets/video/contact.webm";
    vide.appendChild(mov);
    vide.appendChild(webm);
    return () => {};
  }, []);

  return (
    <ContactSection isDropdownOpen={isDropdownOpen} isAgreement={isAgreement}>
      <div className="wrapper">
        {agent === "pc" ? <Navigation type="who" top={90} /> : undefined}
        <div className="img-wrapper">
          <video
            poster="/assets/video/contact-thumbnail.png"
            loop
            muted
            playsInline
            preload="auto"
            onLoadedMetadata={(e) => {
              e.currentTarget.play();
            }}
          >
            {window.safari ? (
              <>
                <source src="/assets/video/contact-3.mov" type="video/mp4" />
                <source src="/assets/video/contact.webm" type="video/webm" />
              </>
            ) : (
              <>
                <source src="/assets/video/contact.webm" type="video/webm" />
                <source src="/assets/video/contact-3.mov" type="video/mp4" />
              </>
            )}
            지원하지 않는 브라우저입니다.
          </video>
        </div>
        <div className="content-wrapper">
          <div className="title">CONTACT US</div>
          <div className="line" />
          <div className="contact_wrapper">
            <div className="contact_container">
              <div className="cat">Phone</div>
              <div className="tel">010-9345-9293</div>
            </div>
            <div className="contact_container">
              <div className="cat">Eamil</div>
              <div className="tel">soon2674715@hanmail.net</div>
            </div>
          </div>
          <form
            className="contact_form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            {layouts_arr.map(({ title, value, type, placeholder }, idx) => {
              return (
                <InputSection
                  key={idx}
                  title={title}
                  value={value}
                  type={type}
                  placeholder={placeholder}
                  update={UpdateInfo}
                />
              );
            })}
            <div className="space_type">
              <div className="type_container">
                <label htmlFor="type">공간유형</label>
                <DropDown
                  options={options}
                  selectedOption={state.type}
                  toggleDropdown={toggleDropdown}
                  update={UpdateInfo}
                />
              </div>
              <div className="select_container">
                <label>평형</label>
                <input
                  type="text"
                  placeholder="평수 "
                  name="feet"
                  value={state.feat}
                  onChange={(e) => {
                    UpdateInfo("feat", e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="description_input">
              <label htmlFor="etc" className="description_label">
                <span className="label-title">기타 문의 사항</span> <br />
                <span className="label-description">
                  도배, 마루, 필름 등 원하는 시공항목과 요청사항을 작성해주세요
                </span>
              </label>
              <textarea
                name="etc"
                id="ect"
                placeholder="내용 입력"
                cols="30"
                rows="10"
                value={state.etc}
                onChange={(e) => {
                  UpdateInfo("etc", e.target.value);
                }}
              ></textarea>
            </div>
            <div className="agreement_container" onClick={onAgreementClick}>
              <div className={isAgreement && "agreement_check"} />
              <span htmlFor="agreement_checkbox">
                상담을 위한 개인정보 사용에 동의합니다
              </span>
            </div>
            <button
              className="button"
              disabled={!isAgreement}
              onClick={SubmitContact}
            >
              상담 신청하기
            </button>
          </form>
        </div>
      </div>
    </ContactSection>
  );
}

export default Contact;

const ContactSection = styled.main`
  height: 100%;
  background-color: #f9f9f9;
  padding-bottom: 372px;
  & > .wrapper {
    position: relative;
    height: 100%;
    padding-top: 173px;
    box-sizing: border-box;
    display: flex;
    padding-left: 165px;
    column-gap: 60px;
    & > .img-wrapper {
      width: 442px;
      height: 600px;
      border-radius: 627px 627px 0 0;
      background-color: white;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: center;
      & > video {
        width: 800px;
      }
    }
    & > .content-wrapper {
      display: flex;
      flex-direction: column;
      width: 477px;
      padding-bottom: 333px;
      font-family: mont;
      .contact_wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .contact_container {
          & > .cat {
            font-size: 15px;
            font-weight: bold;
            margin-bottom: 8px;
          }
          & > .tel {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
      .contact_form {
        display: flex;
        flex-direction: column;
        row-gap: 24px;
        margin-top: 35.3px;
        .input_container {
          display: flex;
          flex-direction: column;
          label {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            margin-bottom: 4px;
          }
          input {
            width: 100%;
            padding: 11px 0px 10px 16px;
            font-size: 16px;
            height: 50px;
            border: 1px solid #dbdbdb;
          }
        }
        .space_type {
          display: flex;
          justify-content: space-between;
          column-gap: 12px;
          .type_container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          div {
            label {
              font-weight: 700;
              margin-bottom: 4px;
            }
          }
          .select_container {
            flex: 1;
            display: flex;
            flex-direction: column;
            input {
              padding: 11px 0px 10px 16px;
              height: 50px;
              font-size: 16px;
              border: 1px solid #dbdbdb;
            }
          }
        }
        .description_input {
          textarea {
            width: 100%;
            margin-top: 8px;
            padding: 11px 0px 0px 16px;
            font-size: 16px;
            font-weight: 400;
            border: 1px solid #dbdbdb;
          }
          .description_label {
            .label-title {
              font-size: 14px;
              font-weight: 700;
            }
            .label-description {
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
        .agreement_container {
          display: flex;
          align-items: center;
          padding: 15px;
          background-color: white;
          margin-right: 8px;
          cursor: pointer;
          div {
            width: 20px;
            height: 20px;
            margin-right: 8px;
            border-radius: 50%;
            border: 1px solid #dbdbdb;
          }
          .agreement_check {
            background-image: url("/assets/contact/check.png");
          }
        }
        .button {
          transition: 0.2 ease-in-out;
          background-color: black;
          margin-top: 52px;
          font-size: 16px;
          font-weight: 700;
          padding: 13px 0px;
          color: white;
        }
        .button:disabled {
          background-color: #dbdbdb;
        }
      }

      & > a {
        margin-top: 12.5px;
        width: 126px;
        height: 44px;
        background-color: black;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding-left: 12px;
        column-gap: 9px;
        margin-bottom: 21px;
        & > img {
          width: 20px;
        }
        & > .text {
          font-weight: 700;
          font-size: 15px;
          color: white;
        }
      }
      & > .title {
        font-size: 40px;
        font-weight: bold;
      }
      & > .line {
        width: 100%;
        height: 1px;
        background-color: black;
        margin: 12px 0;
      }

      & > .email {
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
  @media screen and (max-width: 1365px) {
    & > .wrapper {
      padding-top: 103.3px;
      padding-left: 36px;
      column-gap: 66px;
      & > .img-wrapper {
        width: 649.683px;
        height: 361.151px;
      }
      & > .content-wrapper {
        width: 288px;
        & > .title {
          font-size: 32px;
        }
        & > .cat {
          font-size: 13px;
          margin-bottom: 6px;
        }
        & > .tel {
          font-size: 24px;
        }
        & > .line {
          margin: 12px 0;
        }
        & > .email {
          font-size: 18px;
        }
      }
      .content-wrapper {
        width: 100%;
        .contact_wrapper {
          display: flex;
          flex-direction: column;
          row-gap: 20.4px;
        }
        .contact_form {
          width: 100%;

          .space_type {
            width: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 12px;
            .type_container {
              width: 100%;
            }
            .select_container {
              input {
                width: 100%;
              }
            }
          }
          .agreement_container {
            span {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    height: fit-content;
    padding-bottom: unset;
    & > .wrapper {
      height: fit-content;
      padding-top: 139px;
      padding-left: 0;
      column-gap: unset;
      flex-direction: column;
      row-gap: 52px;
      & > .img-wrapper {
        width: fit-content;
        height: fit-content;
        margin: 0 auto;
        & > video {
          width: 211px;
        }
      }
      & > .content-wrapper {
        width: 100%;
        align-items: center;
        padding-bottom: 88px;
        & > .title {
          font-size: 30px;
        }
        & > .cat {
          font-size: 15px;
          margin-bottom: 8px;
        }
        & > .tel {
          font-size: 36px;
        }
        & > .line {
          margin: 20px 0;
        }
        & > .emial {
          font-size: 20px;
        }
      }
    }
  }
`;
